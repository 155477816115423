import React, { useState, useEffect, useCallback } from "react";
import "./App.css";

type Coordinate = {
  x: number;
  y: number;
};

const initialSnake: Coordinate[] = [{ x: 2, y: 2 }];
const initialFood: Coordinate = { x: 5, y: 5 };
const initialDirection = "RIGHT";
const initialGridSize = { width: 10, height: 10 };

function App() {
  const [snake, setSnake] = useState(initialSnake);
  const [food, setFood] = useState(initialFood);
  const [direction, setDirection] = useState(initialDirection);
  const [gameOver, setGameOver] = useState(false);
  const [gridSize, setGridSize] = useState(initialGridSize);
  const [score, setScore] = useState(0);

  const resetGame = () => {
    setSnake(initialSnake);
    setFood(initialFood);
    setDirection(initialDirection);
    setGameOver(false);
    setScore(0);
  };

  const moveSnake = useCallback(() => {
    setSnake((prevSnake) => {
      const newSnake = [...prevSnake];
      let head = { ...newSnake[0] };

      switch (direction) {
        case "UP":
          head.y -= 1;
          break;
        case "DOWN":
          head.y += 1;
          break;
        case "LEFT":
          head.x -= 1;
          break;
        case "RIGHT":
          head.x += 1;
          break;
      }

      newSnake.unshift(head);
      if (head.x === food.x && head.y === food.y) {
        setScore((score) => score + 1);
        let newFood = {
          x: Math.floor(Math.random() * gridSize.width),
          y: Math.floor(Math.random() * gridSize.height),
        };
        setFood(newFood);
      } else {
        newSnake.pop();
      }

      if (
        head.x < 0 ||
        head.x >= gridSize.width ||
        head.y < 0 ||
        head.y >= gridSize.height ||
        newSnake.slice(1).some((seg) => seg.x === head.x && seg.y === head.y)
      ) {
        setGameOver(true);
      }

      return newSnake;
    });
  }, [direction, food, gridSize]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case "ArrowUp":
          setDirection("UP");
          break;
        case "ArrowDown":
          setDirection("DOWN");
          break;
        case "ArrowLeft":
          setDirection("LEFT");
          break;
        case "ArrowRight":
          setDirection("RIGHT");
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    if (gameOver) {
      return;
    }

    const gameLoop = setInterval(moveSnake, 200);
    return () => clearInterval(gameLoop);
  }, [moveSnake, gameOver]);

  return (
    <div className="game">
      <div className="game-controls">
        <label>
          Width:
          <input
            type="number"
            value={gridSize.width}
            min="5"
            max="40"
            onChange={(e) =>
              setGridSize({ ...gridSize, width: Number(e.target.value) })
            }
          />
        </label>
        <label>
          Height:
          <input
            type="number"
            value={gridSize.height}
            min="5"
            max="40"
            onChange={(e) =>
              setGridSize({ ...gridSize, height: Number(e.target.value) })
            }
          />
        </label>
        <div className="score-board">Score: {score}</div>
      </div>
      <div
        className="game-board"
        style={{
          gridTemplateColumns: `repeat(${gridSize.width}, 20px)`,
          gridTemplateRows: `repeat(${gridSize.height}, 20px)`,
        }}
      >
        {gameOver && (
          <div className="game-over">
            Game Over
            <button onClick={resetGame}>Start</button>
          </div>
        )}
        {Array.from({ length: gridSize.height }, (_, y) =>
          Array.from({ length: gridSize.width }, (_, x) => {
            const isSnake = snake.some(
              (segment) => segment.x === x && segment.y === y
            );
            const isFood = x === food.x && y === food.y;
            return (
              <div
                key={`${x}-${y}`}
                className={`cell ${isSnake ? "snake" : ""} ${
                  isFood ? "food" : ""
                }`}
              />
            );
          })
        )}
      </div>
    </div>
  );
}

export default App;
